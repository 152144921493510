import type { Epic } from 'behavior/types';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { switchMap, mergeMap } from 'rxjs/operators';
import { FEEDBACK_SUBMITTED, feedbackReceived, FeedbackAction } from './actions';
import { feedbackMutation } from './queries';
import { catchApiErrorWithToast, retryWithToast } from 'behavior/errorHandling';
import { resetCaptcha } from 'behavior/captcha';
import { unlockForm, FormLockKeys } from 'behavior/pages';

type FeedbackMutationResponse = {
  contactUs: boolean;
};

const feedbackEpic: Epic<FeedbackAction> = (action$, _, { api, logger }) => action$.pipe(
  ofType(FEEDBACK_SUBMITTED),
  switchMap(action => api.graphApi<FeedbackMutationResponse>(feedbackMutation, { data: action.payload }, { retries: 0 }).pipe(
    mergeMap(result => [feedbackReceived(result.contactUs), resetCaptcha('ContactUs'), unlockForm(FormLockKeys.ContactUs)]),
    catchApiErrorWithToast(['INVALID_INPUT'], of(resetCaptcha('ContactUs'), unlockForm(FormLockKeys.ContactUs))),
    retryWithToast(action$, logger, _ => of(resetCaptcha('ContactUs'), unlockForm(FormLockKeys.ContactUs))),
  )),
);

export default feedbackEpic;
