import { loadRegistrationProcessPageQuery } from './queries';
import { PageComponentNames } from 'behavior/pages';
import { map } from 'rxjs/operators';
import type { Handler } from '../types';
import { getBackTo } from '../helpers';
import { RouteName } from 'routes';
import { initSystemPageContent } from '../system';
import type { RegistrationProcessPage, RegistrationProcessPageResponse, RegistrationProcessRouteData } from './types';

const handler: Handler<RegistrationProcessRouteData, RegistrationProcessPage> = (routeData, state$, { api }) =>
    api.graphApi<RegistrationProcessPageResponse>(loadRegistrationProcessPageQuery).pipe(
        map(({ pages, profile }) => {
            const page = pages.registrationProcess;
            if (!page)
                return null;

            page.templateFields = profile.registrationFields;

            page.component = PageComponentNames.RegistrationProcess;
            page.backTo = getBackTo(state$, [
                RouteName.Login,
                RouteName.RegistrationProcess,
            ], routeData.params && routeData.params.language);

            return { page };
        }),
        initSystemPageContent(),
    );

export default handler;