import {
    CaptchaAction,
    CAPTCHA_RESET,
    CAPTCHA_REMOVED,
} from './actions';

export const initialSyncObj = {};
export const initialState: Record<string, unknown> = {};

export default function reducer(state = initialState, action: CaptchaAction) {
    switch (action.type) {
        case CAPTCHA_RESET:
            return { ...state, [action.payload.captchaId]: {} };
        case CAPTCHA_REMOVED:
            const syncObjects = { ...state };
            delete syncObjects[action.payload.captchaId];
            return syncObjects;
        default:
            return state;
    }
}