import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useSelector } from 'react-redux';
import { useIsVisualDesigner } from 'utils/hooks';

type Props = {
    children: React.ReactNode;
};

const ReCaptchaProvider = ({ children }: Props) => {

    const isDesignerMode = useIsVisualDesigner();
    const reCaptchaSettings = useSelector(state => state.settings.captcha);
    const siteKey = reCaptchaSettings == null || undefined ? '' : reCaptchaSettings.siteKey;

    if (!isDesignerMode)
        return (
            <GoogleReCaptchaProvider reCaptchaKey={siteKey} scriptProps={{ defer: true, async: true }}>
                {children}
            </GoogleReCaptchaProvider>
        );

    return children;
};

export default ReCaptchaProvider;