import { ofType } from 'redux-observable';
import { switchMap, map } from 'rxjs/operators';
import { retryWithToast } from 'behavior/errorHandling';
import {
  CHECKOUT_DISCOUNT_CODE_SAVE,
  checkoutInfoUpdated,
} from './actions';
import { addDiscountCodeMutation } from './queries';
import { skipIfPreview } from 'behavior/preview';
import { navigateOnIncorrect } from './helpers';

export default function createEpic(waitForSubmit) {
  return function (action$, state$, { api, logger }) {

    const isPromotion = () => !!state$.value.page.info?.quote;
    const isQuote = () => state$.value.page.info?.isQuote || false;

    return action$.pipe(
      ofType(CHECKOUT_DISCOUNT_CODE_SAVE),
      skipIfPreview(state$),
      switchMap(({ payload }) => waitForSubmit(() => api.graphApi(addDiscountCodeMutation(isPromotion()), {
        discountCode: payload.info,
        asQuote: isQuote(),
        maxLines: state$.value.settings.checkout.maxOverviewLines + 1,
      }).pipe(
        map(({ checkout }) => {
          if (checkout) {
            const selectResult = checkout.addCoupon;
            if (selectResult.success) {
              return checkoutInfoUpdated(selectResult.info);
            }
          }
          return navigateOnIncorrect(state$.value.page.info);
        }),
        retryWithToast(action$, logger),
      ))),
    );
  };
}
